import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal, Row, Col, Form, Container, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import clientAxios from "../services/api";
import { AuthContext } from "../store/autentication/authContext";
import { ContactsContext } from "../store/contacts/contactsContext";
const DialogTaskReminder = ({ show, setShow }) => {
  // Context
  const { auth } = useContext(AuthContext);
  const { user } = auth;
  const { contacts, removeContact } = useContext(ContactsContext);
  const { selectedContact } = contacts;
  const [formTask, setFormTask] = useState({
    type: "llamada",
    result: "",
    status: 0,
    detail: "",
    remindIn: 0,
    contact_id: "",
    employee_id: "",
  });
  const [isValid, setIsValid] = useState(false);
  const { type, result, status, detail, remindIn } = formTask;
  useEffect(() => {
    if (detail.trim() !== "" && result !== "" && remindIn >= 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    if (show) {
      setFormTask({
        ...formTask,
        contact_id: selectedContact.id,
        employee_id: user.id,
      });
    }
  }, [result, detail, remindIn, show]);

  const handleOnChange = (e) => {
    setFormTask({
      ...formTask,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = () => {
    if (isValid) {
      clientAxios.post("contacts/taskreminder", { ...formTask }).then(
        (r) => {
          toast.success("Guardado", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          const id = formTask.contact_id;
          handleReset();
          //Remove selected contact;
          removeContact(id);
        },
        (e) => {
          const ms = e.message.toString();
          if (ms.indexOf("400")) {
            toast.error("No se pudo guardar, por favor intentalo nuevamente", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("No se pudo guardar, intentalo más tarde", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
        }
      );
    }
  };

  const handleReset = () => {
    setShow(false);
    setFormTask({
      type: "llamada",
      result: "",
      status: 1,
      detail: "",
      remindIn: 1,
    });
  };
  return (
    <Modal
      show={show}
      onHide={handleReset}
      dialogClassName="modal-90w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="modalTaskReminder">Mi Tarea</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {selectedContact ? (
            <Row>
              <Col md={12}>
                <p>
                  <strong>Cliente: </strong>{" "}
                  {selectedContact.name + " " + selectedContact.lastname}
                </p>
              </Col>
              <Col md={12}>
                <p>
                  <strong>Teléfonos: </strong>
                  {selectedContact.phone1 +
                    " " +
                    selectedContact.phone2 +
                    " " +
                    selectedContact.phone3}
                </p>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col md={12}>
              <Form>
                <Form.Group as={Row} controlId="formType">
                  <Form.Label column md="4">
                    Tarea
                    <span> *</span>
                  </Form.Label>
                  <Col md="8">
                    <Form.Control
                      type="text"
                      readOnly
                      name="username"
                      defaultValue={type}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formResult">
                  <Form.Label column md="4">
                    Resultado
                    <span> *</span>
                  </Form.Label>
                  <Col md="8">
                    <Form.Control
                      as="select"
                      name="result"
                      value={result}
                      onChange={(e) => {
                        const v = e.target.value === "volver a llamar" ? 1 : 0;
                        setFormTask({
                          ...formTask,
                          result: e.target.value,
                          status: v,
                        });
                      }}
                    >
                      <option value="">---Selecciona---</option>
                      <option value="contactado">Contactado</option>
                      <option value="no contesta">No contesta</option>
                      <option value="numero incorrecto">
                        Numero incorrecto
                      </option>
                      <option value="volver a llamar">Volver a llamar</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="toClose">
                  <Form.Label column md="4">
                    ¿Cerrar?
                  </Form.Label>
                  <Col md="8">
                    <Form.Check
                      type="switch"
                      name="status"
                      onChange={(e) => {
                        setFormTask({
                          ...formTask,
                          status: e.target.checked ? 0 : 1,
                        });
                      }}
                      checked={!status}
                      disabled={
                        result === "volver a llamar" ||
                        result === "numero incorrecto"
                          ? 1
                          : 0
                      }
                    />
                  </Col>
                </Form.Group>
                {status ? (
                  <Form.Group as={Row} controlId="formRemindIn">
                    <Form.Label column md={4}>
                      Recordar en
                    </Form.Label>
                    <Col md={3}>
                      <Form.Control
                        type="number"
                        value={remindIn}
                        name="remindIn"
                        onChange={(e) =>
                          setFormTask({
                            ...formTask,
                            remindIn:
                              e.target.value < 0
                                ? 0
                                : e.target.value >= 30
                                ? 30
                                : e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={2}> días</Col>
                  </Form.Group>
                ) : (
                  ""
                )}
                <Form.Group as={Row} controlId="formDetail">
                  <Form.Label column md="4">
                    Detalle
                    <span> *</span>
                  </Form.Label>
                  <Col md="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      autoComplete="off"
                      name="detail"
                      value={detail}
                      onChange={handleOnChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ justifyContent: "center" }}>
                  <Button
                    variant="success"
                    type="button"
                    onClick={handleOnSubmit}
                    disabled={!isValid}
                  >
                    Guardar
                  </Button>{" "}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default DialogTaskReminder;
