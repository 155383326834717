import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal, Row, Col, Card } from "react-bootstrap";

import api from "../services/api";
import dayjs from "dayjs";
import clientAxios from "../services/api";
import { ContactsContext } from "../store/contacts/contactsContext";

const DialogTimeline = ({ show, setShow, data }) => {
  const { contacts} = useContext(ContactsContext);
  const { selectedContact } = contacts;
  const [engagements, setListEngagements] = useState([]);
  useEffect(() => {
    if (show) {
      const getData = async () => {
        try {
          const res = await clientAxios.get(`contacts/engagements/${selectedContact.id}`);
          setListEngagements(res["data"].data);
        } catch (e) {
          toast.error("No se pudo obtener los datos, intentalo mas tarde", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      };
      getData();
    }
  }, [show]);

  const handleReset = () => {
    setShow(false);
    setListEngagements([]);
  };
  return (
    <Modal
      show={show}
      onHide={handleReset}
      dialogClassName="modal-90w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Linea de Tiempo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            {engagements.length
              ? engagements.map((e, i) => (
                  <Card style={{ width: "100%" }} key={i}>
                    <Card.Body>
                      <Card.Title>{e.type + " " + e.result}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        {dayjs(e.created_at).format("YYYY-MM-DD hh:mm:ss")}
                      </Card.Subtitle>
                      <Card.Text>{e.detail}</Card.Text>
                    </Card.Body>
                  </Card>
                ))
              : ""}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DialogTimeline;
