import React from "react";
import { Container } from "react-bootstrap";
import { Fragment } from "react";
import Header from "../components/Header";

export default function MainLayout({ children }) {
  return (
    <Fragment>
      <Header />
      <Container fluid>{children}</Container>
    </Fragment>
  );
}
