import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const Report = {
  writeReport: async (data, type) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Reporte Segmento";
    const worksheet = workbook.addWorksheet("reporte");
    worksheet.pageSetup.margins = {
      left: 0.4,
      right: 0.4,
      top: 0.4,
      bottom: 0.4,
      header: 0.3,
      footer: 0.3,
    };
    worksheet.headerFooter.oddFooter = "Pagina &P de &N";
    worksheet.autoFilter = "A1:G1";
    switch (type) {
      case "c":
        worksheet.columns = [
          {
            header: "#",
            key: "id",
            width: 10,
          },
          {
            header: "asesor",
            key: "employee",
            width: 32,
          },
          {
            header: "contacto",
            key: "contact",
            width: 20,
          },
          {
            header: "tipo",
            key: "type",
            width: 20,
          },
          {
            header: "resultado",
            key: "result",
            width: 20,
          },
          {
            header: "detalle",
            key: "detail",
            width: 30,
          },
          {
            header: "fecha",
            key: "date",
            width: 20,
          },
        ];
        data.forEach((e) => {
          worksheet.addRow({
            id: e.id,
            employee: e.asesor,
            contact: e.contacto,
            type: e.tipo,
            result: e.resultado,
            detail: e.detalle,
            date: e.fecha,
          });
        });
        break;
      case "t":
        worksheet.columns = [
          {
            header: "#",
            key: "id",
            width: 10,
          },
          {
            header: "asesor",
            key: "employee",
            width: 20,
          },
          {
            header: "tarea",
            key: "task",
            width: 20,
          },
          {
            header: "apellidos",
            key: "lastname",
            width: 20,
          },
          {
            header: "nombres",
            key: "name",
            width: 20,
          },
          {
            header: "telefono",
            key: "phone",
            width: 20,
          },
          {
            header: "observacion",
            key: "observation",
            width: 20,
          },
        ];
        data.forEach((e, i) => {
          worksheet.addRow({
            id: i + 1,
            employee: e.employee_id,
            task: e.type,
            lastname: e.lastname,
            name: e.name,
            phone: e.phone,
            observation: e.observation,
          });
        });
        break;
      default:
        alert("Sin data");
        break;
    }

    var moment = dayjs().format("DDMMYYYYss");
    await worksheet.protect("(xdu3n>8&d{v)zE%^n+^wt");
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    saveAs(
      new Blob([xls64], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      `reporte${moment}`
    );
  },
};

export default Report;
