import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { AuthContext } from "../..//store/autentication/authContext";
import { statsContext } from "../../store/stadistics/statsContext";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";

const  DashSupervisor = () => {
  
  const { auth } = useContext(AuthContext);
  const [rangeDate, setRangeDate] = useState({
    db: dayjs().format("YYYY-MM-DD"),
    de: dayjs().format("YYYY-MM-DD"),
  });
  const { contacts, engagements, reminders, initStats } =
    useContext(statsContext);
  const [charts, setCharts] = useState({
    contacts: null,
    engagements: null,
    reminders: null,
  });
  const { db, de } = rangeDate;

  useEffect(() => {
      initStats(auth.user.id, `db=${db}&de=${de}`, auth.user.role);
  }, []);
  useEffect(() => {
    setCharts({ ...charts, contacts });
  }, [contacts]);

  useEffect(() => {
    setCharts({ ...charts, engagements });
  }, [engagements]);
  useEffect(() => {
    setCharts({ ...charts, reminders });
  }, [reminders]);

  const handleRange = (e) => {
    setRangeDate({
      ...rangeDate,
      [e.target.name]: dayjs(e.target.value).format("YYYY-MM-DD"),
    });
  };

  return (
    <Fragment>

        <Fragment>
          
          <Row style={{ marginTop: 35 }}>
            <Col md={3} className="float-end">
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="4">
                  <strong>Desde: </strong>
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="date"
                    defaultValue={dayjs().format("YYYY-MM-DD")}
                    placeholder="dd/mm/aaaa"
                    name="db"
                    onChange={handleRange}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={3} className="float-end">
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="4">
                  <strong>Hasta: </strong>
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="date"
                    placeholder="dd/mm/aaaa"
                    name="de"
                    defaultValue={dayjs().format("YYYY-MM-DD")}
                    onChange={handleRange}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Button
                variant="success"
                type="button"
                onClick={() => initStats(auth.user.id, `db=${db}&de=${de}`, auth.user.role)}
              >
                Filtrar
              </Button>{" "}
            </Col>
          </Row>
          <Row style={{ marginTop: 35 }}>
            {charts.contacts ? (
              <Col
                as={Card}
                md={4}
                style={{ paddingLeft: 16, paddingRight: 8 }}
              >
                <ReactApexChart
                  options={charts.contacts.options}
                  series={charts.contacts.series}
                  type="bar"
                  height={350}
                />
              </Col>
            ) : null}
            {charts.engagements ? (
              <Col as={Card} md={4} style={{ paddingLeft: 8, paddingRight: 8 }}>
                <ReactApexChart
                  options={charts.engagements.options}
                  series={charts.engagements.series}
                  type="bar"
                  height={350}
                />
              </Col>
            ) : null}
            {charts.reminders ? (
              <Col as={Card} md={4} style={{ paddingLeft: 8, paddingRight: 8 }}>
                <ReactApexChart
                  options={charts.reminders.options}
                  series={charts.reminders.series}
                  type="bar"
                  height={350}
                />
              </Col>
            ) : null}
          </Row>
        </Fragment>
    </Fragment>
  );
}

export default DashSupervisor;