import { Redirect, Route } from "react-router";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../store/autentication/authContext";

export default function PublicRoute(props) {
  const { auth, getSession } = useContext(AuthContext);
  const { user } = auth;
  useEffect(() => {
    getSession();
  }, []);

  //console.log(auth.user);
  if (user) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
}
