import Types from "../types";

const authReducer = (state, action) => {
  switch (action.type) {
    case Types.loginUser:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
        isServerError: false,
        isLoginError: false,
      };
    case Types.handleLoginError:
      return {
        ...state,
        user: null,
        isLoginError: true,
        isServerError: false,
      };
    case Types.handleServerError:
      return {
        ...state,
        user: null,
        isLoginError: false,
        isServerError: true,
      };
    case Types.getSession: {
      var local = localStorage.getItem("user");
      local = local ? JSON.parse(local) : null;
      return {
        ...state,
        user: local,
      };
    }
    case Types.removeLoginErrors:
    case Types.logout:
      localStorage.removeItem("user");
      return {
        ...state,
        user: null,
        isLoginError: false,
        isServerError: false,
      };
    case Types.setActualRoute:
      localStorage.setItem("route", action.payload);
      return {
        ...state,
        route: action.payload,
      };
    case Types.setProfileColor:
      return {
        ...state,
        user:{...state.user, profile:{...state.user.profile, color: action.payload}}
      }
    case Types.setProfilePhoto:
      localStorage.setItem("user", JSON.stringify({user:{...state.user, profile:{...state.user.profile, photo: action.payload}}}));
      return {
        ...state,
        user:{...state.user, profile:{...state.user.profile, photo: action.payload}}
      }
    

    default:
      return {
        ...state,
      };
  }
};
export default authReducer;
