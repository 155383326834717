import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import Dashboard from "../pages/dashboard";
import Login from "../pages/users/login";
import Profile from "../pages/users/Profile";
import PrivateRoute from "./PrivateRoute";
import AuthProvider from "../store/autentication/authContext";
import ContactUploader from "../pages/tasks/ContactUploader";
import PanelEditor from "../pages/mycontacts/PanelEditor";
import ContactsProvider from "../store/contacts/contactsContext";
import PublicRoute from "./PublicRoute";
import StatsProvider from "../store/stadistics/statsContext";

import { ToastContainer } from "react-toastify";

const AppRouter = () => {
  return (
    <Router>
            <ToastContainer />
      <AuthProvider>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />

          <Route>
            <MainLayout>
              <StatsProvider>
                <Switch>
                  <PrivateRoute exact path="/" component={Dashboard} />
                  <PrivateRoute exact path="/mi-perfil" component={Profile} />
                  <PrivateRoute
                    exact
                    path="/contactos-por-archivo"
                    component={ContactUploader}
                  />
                  <ContactsProvider>
                    <PrivateRoute
                      exact
                      path="/mis-contactos"
                      component={PanelEditor}
                    />
                  </ContactsProvider>
                </Switch>
              </StatsProvider>
            </MainLayout>
          </Route>
        </Switch>
      </AuthProvider>
    </Router>
  );
};

export default AppRouter;
