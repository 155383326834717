import React, { useContext } from "react";
import { useState } from "react";
import { Row, Col, Table, Alert } from "react-bootstrap";


import DialogEngagement from "../../widgets/ModalEngagement";
import DialogReminder from "../../widgets/ModalReminder";

import { ContactsContext } from "../../store/contacts/contactsContext";
import { AuthContext } from "../../store/autentication/authContext";
import DialogTaskReminder from "../../widgets/ModalTaskReminder";
const ContactsEditorNews = () => {
  //modal
  const [show, setShowModal] = useState(false);
  const [showEngagement, setShowEngagement] = useState(false);
  const [showTaskReminder, setShowTaskReminder] = useState(false);

  const { contacts, setSelectedContact } = useContext(ContactsContext);

  return (
    <Row style={{ marginTop: 30 }}>
      <DialogReminder show={show} setShow={setShowModal} />
      <DialogEngagement
        showEngagement={showEngagement}
        setShowEngagement={setShowEngagement}
      />
      <DialogTaskReminder
        show={showTaskReminder}
        setShow={setShowTaskReminder}
      />

      <Col md={12}>
        {contacts.list.length ? (
          <Table responsive="md" striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Nombres</th>
                <th>Apellidos</th>
                <th>Telefonos1</th>
                <th>Telefonos2</th>
                <th>Telefonos3</th>
              </tr>
            </thead>
            <tbody>
              {contacts.list.map((c, i) => (
                <tr
                  key={i}
                  onClick={() => {
                    setSelectedContact(c);
                    setShowTaskReminder(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ fontSize: 10 }}>{i + 1}</td>
                  <td style={{ fontSize: 10 }}>{c.name}</td>
                  <td style={{ fontSize: 10 }}>{c.lastname}</td>
                  <td style={{ fontSize: 10, width: "10%" }}>{c.phone1}</td>
                  <td style={{ fontSize: 10, width: "10%" }}>{c.phone2}</td>
                  <td style={{ fontSize: 10, width: "10%" }}>{c.phone3}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Alert variant="warning" style={{ marginTop: 20 }}>
            <h5>Sin Datos</h5>
          </Alert>
        )}
      </Col>
    </Row>
  );
};

export default ContactsEditorNews;
