import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal, Row, Col, Form, Container, Button } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { AuthContext } from "../store/autentication/authContext";
import { ContactsContext } from "../store/contacts/contactsContext";
import clientAxios from "../services/api";
const DialogReminder = ({ show, setShow }) => {
  registerLocale("es", es);
  const { auth } = useContext(AuthContext);
  const { user } = auth;
  const { contacts} = useContext(ContactsContext);
  const { selectedContact } = contacts;
  const [formTask, setFormTask] = useState({
    type: "",
    date: "",
    detail: "",
    contact_id: "",
    employee_id: "",
  });
  const [isValid, setIsValid] = useState(false);
  const { type, date, detail } = formTask;
  useEffect(() => {
    if (type !== "" && date !== "" && detail.trim() !== "") {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    if (show) {
      setFormTask({
        ...formTask,
        contact_id: selectedContact.id,
        employee_id: user.id,
      });
    }
  }, [type, date, detail, show]);

  const handleOnChange = (e) => {
    setFormTask({
      ...formTask,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = () => {
    if (isValid) {
      var data = formTask;
      data["date"] = dayjs(data["date"]).format("YYYY-MM-DD");
      clientAxios.post("/reminders", { ...formTask }).then(
        (r) => {
          toast.success("Guardado", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setFormTask({
            type: "",
            date: "",
            detail: "",
            contact_id: data[0],
            employee_id: data[1],
          });
        },
        (e) => {
          const ms = e.message.toString();
          if (ms.indexOf("400")) {
            toast.error("Debes añadir una tarea antes de crear un recordario", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(
              "No se pudo guardar el recordatorio, intentalo más tarde",
              {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              }
            );
          }
        }
      );
    }
  };

  const handleReset = () => {
    setShow(false);
    setFormTask({
      type: "",
      date: "",
      detail: "",
      contact_id: "",
      employee_id: "",
    });
  };
  return (
    <Modal
      show={show}
      onHide={handleReset}
      dialogClassName="modal-90w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Crear nuevo recordatorio
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={12}>
              <Form>
                <Form.Group as={Row} controlId="formTask">
                  <Form.Label column md="4">
                    Tarea
                    <span> *</span>
                  </Form.Label>
                  <Col md="8">
                    <Form.Control
                      as="select"
                      name="type"
                      onChange={handleOnChange}
                      value={type}
                    >
                      <option value="">---Selecciona---</option>
                      <option value="llamada"> Llamada</option>
                      <option value="correo">Correo</option>
                      <option value="reunion">Reunion</option>
                      <option value="otro">Otro</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formDate">
                  <Form.Label column md="4">
                    Fecha Vencimiento
                    <span> *</span>
                  </Form.Label>
                  <Col md="8">
                    <DatePicker
                      locale="es"
                      selected={date}
                      className="form-control"
                      onChange={(date) => {
                        setFormTask({
                          ...formTask,
                          date,
                        });
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formDetail">
                  <Form.Label column md="4">
                    Detalle
                    <span> *</span>
                  </Form.Label>
                  <Col md="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      autoComplete="off"
                      name="detail"
                      onChange={handleOnChange}
                      value={detail}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ justifyContent: "center" }}>
                  <Button
                    variant="success"
                    type="button"
                    onClick={handleOnSubmit}
                    disabled={!isValid}
                  >
                    Guardar
                  </Button>{" "}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default DialogReminder;
