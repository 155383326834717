import React, { createContext, useReducer } from "react";
import clientAxios from "../../services/api";
import statsReducer from "./statsReducer";
import Types from "../types";
import generateReport from "../../helpers/generateReport";
import chartGenerator from "../../helpers/chartGenerator";
import dayjs from "dayjs";
export const statsContext = createContext();

const StatsProvider = ({ children }) => {
  const initialState = {
    statsContacts: null,
    statsEngagements: null,
    statsReminders: null,
    hasErrors: [],
  };

  const [stats, dispatch] = useReducer(statsReducer, initialState);

  const getStatsContacts = async (id, params, type) => {
    try {
      const res = await clientAxios.get(`stadistics/contacts/${id}?${params}`);
      // transform
      const data = res.data.data;
      if (data.length > 0) {
        if (type === "supervisor") {
          var accum = data.reduce((acc, val) => {
            if (val["no_contactado"] === undefined) val["no_contactado"] = 0;
            if (val["contactados"] === undefined) val["contactados"] = 0;
            const index = acc.findIndex((el) => el.id === val.id);
            if (index !== -1) {
              if (acc[index]["no_contactado"] === 0 && val["no_contactado"] > 0)
                acc[index]["no_contactado"] = val["no_contactado"];
              if (acc[index]["contactados"] === 0 && val["contactados"] > 0)
                acc[index]["contactados"] = val["contactados"];
            } else {
              acc.push(val);
            }
            return acc;
          }, []);
          const dataSeries = [
            accum.map((e) => e["contactados"]),
            accum.map((e) => e["no_contactado"]),
          ];
          const categories = accum.map((e) => e["asesor"]);

          dispatch({
            type: Types.getStatsContacts,
            payload: chartGenerator.barGroupCategories(
              ["contactados", "no contactados"],
              dataSeries,
              categories,
              () => generateReport(`report/contacts/${id}?${params}`),
              "contactos"
            ),
          });
        }
        if (type === "employee") {
          dispatch({
            type: Types.getStatsContacts,
            payload: [
              data[0][0]["no_contactados"],
              data[0][0]["seguimiento"],
              data[0][1]["recordatorios"],
              data[1]
            ],
          });
        }
      }
    } catch (e) {
      dispatch({
        type: Types.setErrorsStats,
        payload: { property: "statsContacts", error: "Error al obtener datos" },
      });
    }
  };

  const getStatsEngagements = async (id, params, type) => {
    try {
      const res = await clientAxios.get(
        `stadistics/engagements/${id}?${params}`
      );
      // transform
      const data = res.data.data;
      if (data.length) {
        
        if (type === "supervisor") {
          const series_categories = data.reduce(
            (acc, item) => {
              acc[0][0].push(item.llamadas);
              acc[0][1].push(item.correos);
              acc[0][2].push(item.reuniones);
              acc[0][3].push(item.otros);
              acc[1].push(item.asesor);
              return acc;
            },
            [[[], [], [],[]], []]
          );
          dispatch({
            type: Types.getStatsEngagements,
            payload: chartGenerator.barStacked(
              ["llamadas", "correos", "reuniones", "otros"],
              [...series_categories[0]],
              series_categories[1],
              "Tareas"
            ),
          });
        }
        if (type === "employee") {
          dispatch({
            type: Types.getStatsEngagements,
            payload: chartGenerator.barOneCategory(
              Object.values(data[0]).slice(1, 5),
              Object.keys(data[0]).slice(1, 5),
              () => generateReport(`report/contacts/${id}?db=${dayjs().format('YYYY-MM-DD')}`),
              "tareas"
            ),
          });
        }
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: Types.setErrorsStats,
        payload: {
          property: "statsEngagements",
          error: "Error al obtener datos",
        },
      });
    }
  };

  const getStatsReminders = async (id, params) => {
    try {
      const res = await clientAxios.get(`stadistics/reminders/${id}?${params}`);
      // transform
      if (res.data.data.length > 0) {
        var chart = {
          series: [
            {
              name: "Finalizados",
              data: [],
            },
            {
              name: "Cancelados",
              data: [],
            },
            {
              name: "Pendientes",
              data: [],
            },
            {
              name: "Vencidos",
              data: [],
            },
          ],
          options: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: false,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                borderRadius: 8,
                horizontal: false,
              },
            },
            xaxis: {
              type: "string",
              categories: [],
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
          },
        };

        var accum = res.data.data.reduce((acc, val) => {
          if (val["finalizados"] === undefined) val["finalizados"] = 0;
          if (val["vencidos"] === undefined) val["vencidos"] = 0;
          if (val["pendientes"] === undefined) val["pendientes"] = 0;
          if (val["cancelados"] === undefined) val["cancelados"] = 0;
          const index = acc.findIndex((el) => el.id === val.id);
          if (index !== -1) {
            if (acc[index]["finalizados"] === 0 && val["finalizados"] > 0)
              acc[index]["finalizados"] = val["finalizados"];
            if (acc[index]["vencidos"] === 0 && val["vencidos"] > 0)
              acc[index]["vencidos"] = val["vencidos"];
            if (acc[index]["pendientes"] === 0 && val["pendientes"] > 0)
              acc[index]["pendientes"] = val["pendientes"];
            if (acc[index]["cancelados"] === 0 && val["cancelados"] > 0)
              acc[index]["cancelados"] = val["cancelados"];
          } else {
            acc.push(val);
          }
          return acc;
        }, []);

        accum.forEach((e) => {
          chart.series[0]["data"].push(e["finalizados"]);
          chart.series[1]["data"].push(e["cancelados"]);
          chart.series[2]["data"].push(e["pendientes"]);
          chart.series[3]["data"].push(e["vencidos"]);

          chart.options.xaxis.categories.push(e["asesor"]);
        });
        dispatch({ type: Types.getStatsReminders, payload: chart });
      }
    } catch (e) {
      dispatch({
        type: Types.setErrorsStats,
        payload: {
          property: "statsEngagements",
          error: "Error al obtener datos",
        },
      });
    }
  };

  const initStats = (id, params, type) => {
    switch (type) {
      case "supervisor":
        getStatsContacts(id, params, type);
        getStatsEngagements(id, params, type);
        getStatsReminders(id, params);
        break;
      case "employee":
        getStatsContacts(id, params, type);
        getStatsEngagements(id, params, type);
        break;
      default:
        return;
    }
  };

  return (
    <statsContext.Provider
      value={{
        contacts: stats.statsContacts,
        engagements: stats.statsEngagements,
        reminders: stats.statsReminders,
        initStats,
      }}
    >
      {children}
    </statsContext.Provider>
  );
};

export default StatsProvider;
/*var chart = {
          series: [
            {
              name: "contactados",
              data: [],
            },
            {
              name: "no contactados",
              data: [],
            },
          ],
          options: {
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: true,
                tools: {
                  download: false,
                  customIcons: [
                    {
                      icon: '<i class="fas fa-file-excel" style="color: green"></i>',
                      index: 0,
                      title: "Reporte Detallado",
                      appendTo: "right",
                      click: () =>
                        generateReport(`report/contacts/${id}?${params}`),
                    },
                  ],
                },
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            xaxis: {
              categories: [],
            },
            yaxis: {
              title: {
                text: "Contactos",
              },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val + " contactos";
                },
              },
            },
          },
        };*/
