import React from "react";
import {
  FcCollaboration,
  FcAdvertising,
  FcKindle,
  FcCallback,
} from "react-icons/fc";
const AlertBadge = ({ data, header }) => {
  console.log("me renderizo badge");
  const headerBadge = {
    backgroundColor:
      header === "Vencidos"
        ? "#f14e4e"
        : header === "Hoy"
        ? "orange"
        : header === "Mañana"
        ? "lightblue"
        : header === "Semana"
        ? "lightgreen"
        : "green",
    borderRadius: 8,
    color: "white",
    fontWeight: 400,
  };
  const countBadge = {
    backgroundColor: "orange",
    borderRadius: 9,
    color: "white",
    position: "relative",
    top: -10,
    left: -4,
    fontSize: 9,
  };
  return (
    <div>
      <p style={headerBadge}>{header}</p>
      {data[0] ? (
        <span style={{ padding: 0, margin: 0 }} title={"Llamadas " + data[0]}>
          <FcCallback size={26} />
          <span style={countBadge}> {data[0]} </span>{" "}
        </span>
      ) : (
        ""
      )}
      {data[1] ? (
        <span style={{ padding: 0, margin: 0 }} title={"Correos " + data[0]}>
          <FcKindle size={26} />
          <span style={countBadge}> {data[1]} </span>{" "}
        </span>
      ) : (
        ""
      )}
      {data[2] ? (
        <span style={{ padding: 0, margin: 0 }} title={"Reuniones " + data[0]}>
          <FcCollaboration size={26} />
          <span style={countBadge}> {data[2]} </span>{" "}
        </span>
      ) : (
        ""
      )}
      {data[3] ? (
        <span style={{ padding: 0, margin: 0 }} title={"Otros " + data[0]}>
          <FcAdvertising size={26} />
          <span style={countBadge}> {data[3]} </span>{" "}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default AlertBadge;
