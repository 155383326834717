import React, { useContext, Fragment } from "react";
import { useState, useRef } from "react";
import { Table, Form, Row, Col, Button, Alert } from "react-bootstrap";
import readXlsxFile from "read-excel-file";
import clientAxios from "../../services/api";
import { AuthContext } from "../../store/autentication/authContext";
import { ToastContainer, toast } from "react-toastify";
const ContactUploader = () => {
  const [dataFile, setDataFile] = useState([]);
  const [hasErrors, setHasErrors] = useState({
    error: false,
    msg: "",
  });
  const fileInput = useRef();
  const { auth } = useContext(AuthContext);
  const { user } = auth;
  const team =
    "team" in user
      ? user.role === "supervisor"
        ? [...user.team.map((t) => t.username), user.username]
        : [user.username]
      : null;

  const schema = {
    asesor: {
      prop: "asesor",
      type: String,
      required: true,
      oneOf: team,
    },
    nombres: {
      prop: "nombres",
      type: String,
      required: true,
    },
    apellidos: {
      prop: "apellidos",
      type: String,
      required: true,
    },
    telefonos1: {
      prop: "telefonos1",
      type: String,
      required: true,
    },
    telefonos2: {
      prop: "telefonos2",
      type: String,
    },
    telefonos3: {
      prop: "telefonos3",
      type: String,
    },
  };

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (
      file.size > 100 &&
      (file.name.split(".")[1] === "xls" || file.name.split(".")[1] === "xlsx")
    ) {
      const toXls = async () => {
        const { rows, errors } = await readXlsxFile(file, { schema });
        if (errors.length) {
          setHasErrors({
            error: true,
            msg: "El archivo no cumple con la estructura",
          });
          setDataFile(errors);
        } else if (rows.length) {
          setHasErrors({ error: false, msg: "" });
          if ("team" in user) {
            var team = [...user.team, { username: user.username, id: user.id }];
            rows.forEach((r) => {
              r["employee_id"] = team.filter((e) =>
                e.username === r.asesor ? e.id : ""
              )[0].id;
            });
          } else {
            rows.forEach((r) => {
              r["employee_id"] = user.id;
            });
          }
          setDataFile(rows);
        } else {
          setHasErrors({ error: true, msg: "El archivo esta vacio" });
        }
      };
      toXls();
    } else {
      setHasErrors({ error: true, msg: "El archivo no es valido " });
    }
  };

  const handleSubmit = async (e) => {
    const data = dataFile;
    setDataFile([]);
    e.preventDefault();
    try {
      await clientAxios.post("contacts", {
        data: JSON.stringify(data),
      });
      toast.success("Se han registrado todos los contactos correctamente", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setDataFile([]);
    } catch (error) {
      toast.error("No se pudo guardar los contactos, intentalo más tarde", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <Row style={{ marginTop: 30 }}>
        <Col md={{ span: 4, offset: 4 }}>
          <Form autoComplete="false" onSubmit={handleSubmit}>
            <Form.File
              id="fileActions"
              style={{
                backgroundColor: "#ece8e8",
                padding: "20px 10px 10px 20px",
                borderRadius: "5px",
              }}
            >
              <Form.File.Label>Archivo Excel *</Form.File.Label>
              <Form.File.Input
                ref={fileInput}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleChangeFile}
                onClick={(e) => {
                  e.target.value = null;
                  setHasErrors({ error: false, msg: "" });
                  setDataFile([]);
                }}
              />
            </Form.File>
            <Form.Group
              as={Row}
              style={{ justifyContent: "center", marginTop: "30px" }}
            >
              <Button
                variant="success"
                type="submit"
                disabled={!dataFile.length || hasErrors.error}
              >
                Subir
              </Button>{" "}
            </Form.Group>
          </Form>
          {hasErrors.error && dataFile.length === 0 ? (
            <Alert variant={"danger"}>{hasErrors.msg}</Alert>
          ) : hasErrors.error && dataFile.length > 0 ? (
            <Alert variant={"warning"}>{hasErrors.msg}</Alert>
          ) : !hasErrors.error && dataFile.length > 0 ? (
            <Alert variant={"success"}>
              Archivo validado, puedes subirlo sin problemas
            </Alert>
          ) : null}
        </Col>
      </Row>
      {hasErrors.error && dataFile.length > 0 ? (
        <Row style={{ marginTop: 35 }}>
          <Col md={12}>
            <Table responsive="md" striped bordered hover>
              <thead>
                <tr>
                  <th>Fila</th>
                  <th>Columna</th>
                  <th>Valor</th>
                  <th>Error</th>
                </tr>
              </thead>
              <tbody>
                {dataFile.map((d, i) => (
                  <tr key={i}>
                    <td>{d.row}</td>
                    <td>{d.column}</td>
                    <td>{d.value === null ? "vacio" : d.value}</td>
                    <td>
                      {d.error === "required"
                        ? "Es obligatorio"
                        : d.error === "invalid"
                        ? "No es valido"
                        : d.error}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : null}
    </Fragment>
  );
};

export default ContactUploader;
