import clientAxios from "../services/api";
import Report from "../lib/report";
const generateReport = async (route) => {
  try {
    const rs = await clientAxios.get(route);
    Report.writeReport(rs.data.data, "c");
  } catch (e) {
    console.log(e);
    alert("Error no se pudo generar el reporte");
  }
};
export default generateReport;
