import Types from "../types";

const contactsReducer = (state, action) => {
  switch (action.type) {
    case Types.getContacts:
      return {
        ...state,
        list: action.payload,
      };
    case Types.setSelectedContact:
      return {
        ...state,
        selectedContact: action.payload,
      };
    case Types.removeContact:
      return {
        ...state,
        list: state.list.filter(e=>e.id !== action.payload)
      }
    default:
      return state;
  }
};

export default contactsReducer;
