import React, { useContext } from "react";
import { useState, useEffect, Fragment } from "react";
import { toast } from "react-toastify";
import { CgGhostCharacter } from "react-icons/cg";
import {
  Modal,
  Row,
  Col,
  Table,
  Form,
  Container,
  Button,
} from "react-bootstrap";

import dayjs from "dayjs";
import config from "../config/api.json";
import clientAxios from "../services/api";
import { ContactsContext } from "../store/contacts/contactsContext";

const DialogListReminders = ({ show, setShow }) => {
  const { contacts } = useContext(ContactsContext);

  const { selectedContact } = contacts;
  const [reminders, setListReminders] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [actualReminder, setActualReminder] = useState({
    id: "",
    type: "",
  });
  const [isFormValid, setFormValid] = useState(false);
  const [form, setForm] = useState({
    status: 1,
    result: "otro",
    detail: "",
  });
  const { status, result, detail } = form;
  useEffect(() => {
    if (show) {
      const getData = async () => {
        await clientAxios
          .get(`contacts/reminders/${selectedContact.id}`)
          .then((r) => {
            setListReminders(r.data.data);
          })
          .catch((e) => {
            toast.error("No se pudo obtener los datos, intentalo mas tarde", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          });
      };
      getData();
    }
  }, [show]);

  useEffect(() => {
    if (status !== "" && result !== "" && detail !== "") {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [status, result, detail]);

  const handleReset = () => {
    setShow(false);
  };
  const handleAction = () => {
    setShowAction(false);
  };

  const handleOnChangeForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (isFormValid) {
      setFormValid(false);
      clientAxios
        .put("reminder/engagement", {
          ...actualReminder,
          ...form,
        })
        .then(
          (r) => {
            toast.success("Guardado", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            setForm({
              status: 1,
              result: "otro",
              detail: "",
            });
            setActualReminder({
              id: "",
              type: "",
            });
            showAction(false);
          },
          (e) => {
            setFormValid(true);
            toast.error("Ocurrio un error al guardar ç", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
        );
    }
  };
  return (
    <Fragment>
      <Modal
        size="lg"
        show={show}
        onHide={handleReset}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Mis tareas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Table responsive="md" striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Tipo</th>
                    <th>Fecha Hasta</th>
                    <th>Detalle</th>
                    <th>Creado el</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {reminders.map((r, i) => {
                    const until = dayjs(r.until_date);
                    const rs = until.diff(dayjs(), "days", true);
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r.type}</td>
                        <td style={{ textAlign: "center" }}>
                          {
                            <div>
                              <p>
                                <strong>
                                  {dayjs(r.until_date).format("DD/MM/YYYY")}
                                </strong>
                              </p>
                              <p
                                style={{ fontSize: 10 }}
                                className={
                                  Math.round(rs) >= 1
                                    ? "bg-success"
                                    : rs === 0
                                    ? "bg-warning"
                                    : "bg-danger"
                                }
                              >
                                {" "}
                                {Math.round(rs) >= 1
                                  ? "A Tiempo"
                                  : rs === 0
                                  ? "Hoy"
                                  : "Vencido"}
                              </p>
                            </div>
                          }
                        </td>
                        <td>{r.detail}</td>
                        <td>
                          {dayjs(r.created_at).format("DD/MM/YYYY hh:mm:ss")}
                        </td>
                        <td>
                          {r.status === 1 ? (
                            <p className="bg-warning">Pendiente</p>
                          ) : r.status === 0 ? (
                            <p className="bg-success">Completado</p>
                          ) : (
                            <p className="bg-danger">Cancelado</p>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {r.status === 1 ? (
                            <Button
                              variant="primary"
                              type="button"
                              onClick={() => {
                                setShowAction(true);
                                setActualReminder({
                                  id: r.id,
                                  type: r.type,
                                });
                              }}
                            >
                              Gestionar
                            </Button>
                          ) : (
                            <CgGhostCharacter size={30} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={showAction}
        onHide={handleAction}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Completar Recordatorio
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12}>
                <Form>
                  <Form.Group as={Row} controlId="formTask">
                    <Form.Label column md="4">
                      Estado
                      <span> *</span>
                    </Form.Label>
                    <Col md="8">
                      <Form.Control
                        as="select"
                        name="status"
                        value={status}
                        onChange={handleOnChangeForm}
                      >
                        <option value="">---Selecciona---</option>
                        <option value="0">Completar</option>
                        <option value="2">Anular</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  {status === "0" ? (
                    <Form.Group as={Row} controlId="formTask">
                      <Form.Label column md="4">
                        Resultado
                        <span> *</span>
                      </Form.Label>
                      <Col md="8">
                        <Form.Control
                          as="select"
                          name="result"
                          value={result}
                          onChange={handleOnChangeForm}
                        >
                          <option value="">---Selecciona---</option>
                          {config.tasks[actualReminder["type"]].map((t, i) => (
                            <option value={t} key={i}>
                              {t}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  ) : (
                    ""
                  )}

                  <Form.Group as={Row} controlId="formDetail">
                    <Form.Label column md="4">
                      Detalle
                      <span> *</span>
                    </Form.Label>
                    <Col md="8">
                      <Form.Control
                        as="textarea"
                        value={detail}
                        rows={3}
                        autoComplete="off"
                        name="detail"
                        onChange={handleOnChangeForm}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} style={{ justifyContent: "center" }}>
                    <Button
                      variant="success"
                      type="button"
                      disabled={!isFormValid}
                      onClick={handleSubmit}
                    >
                      Guardar
                    </Button>{" "}
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default DialogListReminders;
