import React, { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/autentication/authContext";
import { statsContext } from "../../store/stadistics/statsContext";
import ReactApexChart from "react-apexcharts";
const DashEmployee = () => {
  const { auth } = useContext(AuthContext);
  const { contacts, engagements, initStats } = useContext(statsContext);
  const [charts, setCharts] = useState({
    contacts: null,
    engagements: null,
  });

  useEffect(() => initStats(auth.user.id, `db=all`, auth.user.role), []);

  useEffect(() => {
    setCharts({ ...charts, contacts });
  }, [contacts]);
  useEffect(() => {
    setCharts({ ...charts, engagements });
  }, [engagements]);

  return (
    <Fragment>
      <div className="row mt-5">
        <div className="col rounded mr-2 mb-2">
          <div className="card shadow">
            <div className="card-body">
              <h5 className="card-title">En seguimiento</h5>

              <h2 className="card-text text-center">
                <i className="fas fa-users" style={{ color: "#ec9121f2" }}></i>
                {!charts.contacts ? 0 : charts.contacts[0]}
              </h2>
            </div>
          </div>
        </div>
        <div className="col rounded mr-2 mb-2">
          <div className="card shadow">
            <div className="card-body">
              <h5 className="card-title">Por contactar</h5>

              <h2 className="card-text text-center">
                <i className="fas fa-phone" style={{ color: "#14bd14" }}></i>
                {!charts.contacts ? 0 : charts.contacts[1]}
              </h2>
            </div>
          </div>
        </div>
        <div className="col rounded">
          <div className="card shadow">
            <div className="card-body">
              <h5 className="card-title">Recordatorios</h5>

              <h2 className="card-text text-center">
                <i className="fas fa-bell" style={{ color: "#007eff" }}>
                  {" "}
                </i>
                {!charts.contacts ? 0 : charts.contacts[2]}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-8 px-md-2">
          <div className="card shadow">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <h5 className="card-title">
                    <i className="fas fa-briefcase"> </i>
                    Total Tareas
                  </h5>
                </div>
                <div className="col-md-3">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="dd/mm/yyyy"
                    aria-describedby="date1-dash"
                  />
                </div>
              </div>
              {charts.engagements ? (
                <ReactApexChart
                  options={charts.engagements.options}
                  series={charts.engagements.series}
                  type="bar"
                  height={350}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-md-4 px-md-2">
          <div className="card shadow">
            <div className="card-body">
              <h5 className="card-title">
                <i className="fas fa-briefcase"></i> Mis últimas tareas
              </h5>
              <div className="card-text v-scrollable">
                {charts.contacts ? (
                  charts.contacts[3].length ? (
                    charts.contacts[3].map((e, i) => (
                      <div key={i + 1} className="row mt-2 mb-2">
                        <div className="col-12">
                          <div className="card shadow-sm">
                            <div className="card-body">
                              <h5 className="card-title">
                                {e.tipo + " / " + e.resultado}
                              </h5>
                              <h6 className="card-subtitle mb-2 text-muted">
                                {e.contacto + " / " + e.fecha}
                              </h6>
                              <p className="card-text">{e.detalle}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h5>Sin datos</h5>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DashEmployee;
