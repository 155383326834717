import React, { useContext, useEffect, useState } from "react";
import reactCSS from "reactcss";
import {SketchPicker } from "react-color";
import { Card, Col, Row, Form, Button, Alert } from "react-bootstrap";
import { AuthContext } from "../../store/autentication/authContext";
import nophoto from '../../assets/user.png';
import base64Reader from '../../helpers/base64Reader';
import "./profile.css";
import { toast } from "react-toastify";
import clientAxios from "../../services/api";
const Profile = () => {
  //Context
  const { auth, setProfileColor, setProfilePhoto } = useContext(AuthContext);
  const [hasError, setHasError] = useState({
    valid: false,
    error: "",
  });
  const { user } = auth;
  const [photo, setPhoto] = useState(user.profile.photo);
  const [profile, setProfile] = useState({
    username: user.username,
    firstname: user.profile.firstname,
    lastname: user.profile.lastname,
    email: user.profile.email,
    displayColorPicker: false,
    color: user.profile.color,
    password: "",
    cpassword: "",
  });

  const { username, firstname, lastname, email, password, cpassword } = profile;

  useEffect(() => {
    if (password !== cpassword) {
      setHasError({ valid: false, error: "Las contraseñas deben ser iguales" });
    } else if (
      password !== "" &&
      !password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/)
    ) {
      setHasError({
        valid: false,
        error:
          "La contraseña debe contener al menos 8 caracteres e incluir al menos una mayúscula, una minuscula y un número",
      });
    }else if(password === cpassword && password !== '' && cpassword !=='' ) setHasError({valid: true, error:''});

  }, [password, cpassword]);

  // Color Picker
  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: `${profile.color}`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  // Color picker handles
  const handleChangeColor = (color) => {
    setProfile({
      ...profile,
      color: color.hex,
    });
    setProfileColor(color.hex);
  };

  // handle photo
  const handlePhoto =  async(e) => {
    if(e.target.files && e.target.files[0]){
      const filephoto = e.target.files[0];
      if(filephoto.type === 'image/jpeg' || filephoto.type === 'image/png'){
        const base64 = await base64Reader(filephoto);
        setPhoto(base64);
      }else {
        toast.error("Debes escoger una foto", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  const handleChangeProfile = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try{
      var data = {password};
      data['color'] = profile.color;
      if(user.profile.photo !== photo) {
        data['photo'] = photo;
      }
      await clientAxios.put(`user/${user.id}`,{...data});
      toast.success("Tu perfil se actualizo correctamente", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setProfilePhoto(photo);
      setProfile({
        ...profile,
        password: "",
    cpassword: "",
      })
    }catch(e){
      console.log(e);
      toast.error("Ocurrio un error al actualizar, intetalo más tarde", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Row style={{ marginTop: 30 }}>
      <Col as={Card} md={{ span: 6, offset: 3 }} style={{ borderRadius: 15 }}>
        <Form className="mt-3 ml-3 mr-3" onSubmit={handleSubmit}>
          <Form.Group
            as={Row}
            className="mt-2 mb-3"
            controlId="formProfilePhoto"
          >
            <Col md={{ span: 4, offset: 4 }}>
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handlePhoto}
                  />
                  <label htmlFor="imageUpload"></label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="imagePreview"
                    style={{
                      backgroundImage: `url(${!photo ? nophoto: photo})`,
                    }}
                  ></div>
                </div>
              </div>
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mt-2 mb-3"
            controlId="formPlainUsername"
          >
            <Form.Label column sm="3">
              <strong>Usuario: </strong>
            </Form.Label>
            <Col sm="9">
              <Form.Control readOnly defaultValue={username} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlainFirstname">
            <Form.Label column sm="3">
              <strong>Nombres: </strong>
            </Form.Label>
            <Col sm="9">
              <Form.Control readOnly defaultValue={firstname} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlainLastname">
            <Form.Label column sm="3">
              <strong>Apellidos: </strong>
            </Form.Label>
            <Col sm="9">
              <Form.Control defaultValue={lastname} readOnly />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlainEmail">
            <Form.Label column sm="3">
              <strong>Correo: </strong>
            </Form.Label>
            <Col sm="9">
              <Form.Control readOnly defaultValue={email} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formColorEmail">
            <Form.Label column sm="3">
              <strong>Color: </strong>
            </Form.Label>
            <Col sm="3">
              <div
                style={styles.swatch}
                onClick={() =>
                  setProfile({
                    ...profile,
                    displayColorPicker: !profile.displayColorPicker,
                  })
                }
              >
                <div style={styles.color} />
              </div>
              {profile.displayColorPicker ? (
                <div style={styles.popover}>
                  <div
                    style={styles.cover}
                    onClick={() =>
                      setProfile({ ...profile, displayColorPicker: false })
                    }
                  />
                  <SketchPicker
                    color={profile.color}
                    onChange={handleChangeColor}
                  />
                </div>
              ) : null}
            </Col>
          </Form.Group>
          {!hasError.valid && hasError.error? (
            <Alert variant="danger" className="mt-4 mb-4">
              {hasError.error}
            </Alert>
          ) : null}
          <Form.Group as={Row} className="mb-3" controlId="formPlainPassword">
            <Form.Label column sm="3">
              <strong>Contraseña: </strong>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                value={password}
                name="password"
                type="password"
                placeholder="Contraseña"
                onChange={handleChangeProfile}
                autoComplete="new-password"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlainCPassword">
            <Form.Label column sm="3">
              <strong>Confirmar: </strong>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                value={cpassword}
                name="cpassword"
                type="password"
                placeholder="Confirmar contraseña"
                onChange={handleChangeProfile}
                autoComplete="new-password"
              />
            </Col>
          </Form.Group>
          <Row className="mt-2 mb-3">
            <Col md={12} style={{ textAlign: "center" }}>
              <Button
                variant="success"
                type="submit"
                disabled={!hasError.valid}
              >
                Guardar
              </Button>{" "}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default Profile;
