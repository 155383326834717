import React, { useContext, Fragment } from "react";
import { useState, useEffect } from "react";
import { FcDocument, FcAssistant, FcEndCall } from "react-icons/fc";
import { Tabs, Tab, Fade } from "react-bootstrap";
import ContactEditorActive from "./ContactEditorActive";
import ContactEditorNews from "./ContactEditorNews";
import ContactsEditorDisabled from "./ContactEditorDisabled.js";
import { ContactsContext } from "../../store/contacts/contactsContext";
import { AuthContext } from "../../store/autentication/authContext";


const PanelEditor = () => {
  const [selectedTab, setSelectedTab] = useState("news");
  const { auth } = useContext(AuthContext);
  const { getContacts } = useContext(ContactsContext);

  useEffect(() => {
    getContacts({ selectedTab, user: auth.user.id });
  }, [selectedTab]);

  return (
    <Fragment>

      <Tabs
        defaultActiveKey="news"
        style={{ marginTop: 30 }}
        id="paneleditor"
        transition={Fade}
        onSelect={(e) => setSelectedTab(e)}
      >
        <Tab
          eventKey="news"
          title={
            <Fragment>
              <FcDocument />
              <span> Nuevos</span>
            </Fragment>
          }
        >
          <ContactEditorNews />
        </Tab>
        <Tab
          eventKey="actives"
          title={
            <Fragment>
              <FcAssistant />
              <span> En Seguimiento</span>
            </Fragment>
          }
        >
          <ContactEditorActive />
        </Tab>
        <Tab
          disabled
          eventKey="inactives"
          title={
            <Fragment>
              <FcEndCall />
              <span> Inactivos</span>
            </Fragment>
          }
        ></Tab>
      </Tabs>
    </Fragment>
  );
};

export default PanelEditor;
