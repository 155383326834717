import React, { createContext, useReducer } from "react";
import clientAxios from "../../services/api";
import authReducer from "./authReducer";
import status from "../../helpers/status";
import Types from "../types";

export const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const initialState = {
    user: null,
    isServerError: false,
    isLoginError: false,
  };
  const [auth, dispatch] = useReducer(authReducer, initialState);

  const login = async (datos) => {
    try {
      const result = await clientAxios.post("/users/login", datos);
      // Validate role
      var user = result.data["data"];

      if (user.role === "admin" || user.role === "supervisor") {
        user["team"] = result.data.team.map((t) => {
          return {
            username: t.username,
            id: t.id,
          };
        });
      }
      dispatch({ type: Types.loginUser, payload: user });
    } catch (e) {
      const code = status(e.message.toString());
      if (code === "401") {
        dispatch({ type: Types.handleLoginError });
      }
      if (code === "500") {
        dispatch({ type: Types.handleServerError });
      }
      setTimeout(() => {
        dispatch({ type: Types.removeLoginErrors });
      }, 5000);
    }
  };

  const getSession = () => {
    dispatch({ type: Types.getSession });
  };

  const logout = () => {
    dispatch({ type: Types.logout });
  };

  const setActualRoute = (route) => {
    dispatch({ type: Types.setActualRoute, payload: route.pathname });
  };

  const setProfileColor = (color)=> {
    dispatch({type: Types.setProfileColor, payload: color});
  }

  const setProfilePhoto = (photo) => {
    dispatch({type: Types.setProfilePhoto, payload: photo});
  }

  return (
    <AuthContext.Provider
      value={{ auth, login, getSession, logout, setActualRoute,setProfileColor,setProfilePhoto}}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
