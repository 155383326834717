import { useContext, useState } from "react";
import { Row, Col, Form, Table, Alert, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { FcSettings, FcFilingCabinet } from "react-icons/fc";
import DialogReminder from "../../widgets/ModalReminder";
import DialogEngagement from "../../widgets/ModalEngagement";
import DialogTimeline from "../../widgets/ModalTimeline";
import DialogListReminder from "../../widgets/ModalListReminders";
import AlertBadge from "../../widgets/Badgets";
import React from "react";
import { ContactsContext } from "../../store/contacts/contactsContext";
import { AuthContext } from "../../store/autentication/authContext";
import clientAxios from "../../services/api";
const ContactsEditorActive = () => {
  const [show, setShowModal] = useState(false);
  const [showEngagement, setShowEngagement] = useState(false);
  const [showTimeline, setShowTimeline] = useState(false);

  const [showReminders, setShowReminders] = useState(false);
  const today = dayjs(new Date()).format("YYYY-MM-DD");
  const { contacts, setSelectedContact } = useContext(ContactsContext);
  const { auth } = useContext(AuthContext);
  const { user } = auth;
  const handleRowChange = async (e, id) => {
    const selected = e.target.value;
    await clientAxios
      .put("contacts/status", {
        status: selected,
        contact_id: id,
        employee_id: user.id,
      })
      .then((r) => {
        toast.success("Se actualizo correctamente", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((e) => {
        const ms = e.message.toString();
        if (ms.indexOf("400")) {
          toast.error("Debes añadir una tarea antes de actualizar el estado", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("No se pudo guardar el estado, intentalo más tarde", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <Row style={{ marginTop: 30 }}>
      <DialogReminder show={show} setShow={setShowModal} />
      <DialogEngagement
        showEngagement={showEngagement}
        setShowEngagement={setShowEngagement}
      />
      <DialogTimeline show={showTimeline} setShow={setShowTimeline} />
      <DialogListReminder show={showReminders} setShow={setShowReminders} />
      <Col md={12}>
        {contacts.list.length && user.id ? (
          <Table responsive="md" striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Nombres</th>
                <th>Apellidos</th>
                <th>Telefonos1</th>
                <th>Telefonos2</th>
                <th>Telefonos3</th>
                <th>Estado</th>
                <th>Historial</th>
                <th>Recordatorios</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {contacts.list.map((c, i) => {
                //var hasTodayandTomorrow = false;
                var hasYesterday = [0, 0, 0, 0],
                  hasToday = [0, 0, 0, 0],
                  hasTomorrow = [0, 0, 0, 0],
                  hasWeek = [0, 0, 0, 0],
                  hasMonth = [0, 0, 0, 0];
                if (c.reminders.length) {
                  c.reminders.forEach((r) => {
                    const until = dayjs(r.until_date.split(" ")[0]);
                    const days = until.diff(dayjs(today), "days");
                    switch (r.type) {
                      case "llamada":
                        days < 0
                          ? (hasYesterday[0] += 1)
                          : days === 0
                          ? (hasToday[0] += 1)
                          : days === 1
                          ? (hasTomorrow[0] += 1)
                          : days > 1 && days <= 7
                          ? (hasWeek[0] += 1)
                          : (hasMonth[0] += 1);

                        break;
                      case "correo":
                        days < 0
                          ? (hasYesterday[1] += 1)
                          : days === 0
                          ? (hasToday[1] += 1)
                          : days === 1
                          ? (hasTomorrow[1] += 1)
                          : days > 1 && days <= 7
                          ? (hasWeek[1] += 1)
                          : (hasMonth[1] += 1);
                        break;
                      case "reunion":
                        days < 0
                          ? (hasYesterday[2] += 1)
                          : days === 0
                          ? (hasToday[2] += 1)
                          : days === 1
                          ? (hasTomorrow[2] += 1)
                          : days > 1 && days <= 7
                          ? (hasWeek[2] += 1)
                          : (hasMonth[0] += 1);
                        break;
                      default:
                        days < 0
                          ? (hasYesterday[3] += 1)
                          : days === 0
                          ? (hasToday[3] += 1)
                          : days === 1
                          ? (hasTomorrow[3] += 1)
                          : days > 1 && days <= 7
                          ? (hasWeek[3] += 1)
                          : (hasMonth[0] += 1);
                        break;
                    }
                  });
                }

                return (
                  <tr key={i}>
                    <td style={{ fontSize: 10 }}>{c.name}</td>
                    <td style={{ fontSize: 10 }}>{c.lastname}</td>
                    <td style={{ fontSize: 10, width: "8%" }}>{c.phone1}</td>
                    <td style={{ fontSize: 10, width: "8%" }}>{c.phone2}</td>
                    <td style={{ fontSize: 10, width: "8%" }}>{c.phone3}</td>
                    <td style={{ fontSize: 10, width: "10%" }}>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={(e) => handleRowChange(e, c.id)}
                      >
                        <option value="1">Activo</option>
                        <option value="0">Cerrado</option>
                      </Form.Control>
                    </td>
                    <td
                      style={{
                        fontSize: 10,
                        textAlign: "center",
                      }}
                    >
                      {c.engagements.length ? (
                        <button
                          onClick={() => {
                            setShowTimeline(true);
                            setSelectedContact(c);
                          }}
                          type="button"
                          className="btn btn-sm"
                        >
                          <FcFilingCabinet size={23} />{" "}
                          <span
                            style={{
                              backgroundColor: "orange",
                              borderRadius: 9,
                              color: "white",
                              position: "relative",
                              top: -10,
                              left: -4,
                              fontSize: 9,
                              padding: 2,
                            }}
                          >
                            {c.engagements.length}
                          </span>
                        </button>
                      ) : (
                        <p
                          style={{
                            color: "white",
                            backgroundColor: "black",
                            borderRadius: 8,
                          }}
                        >
                          Sin datos
                        </p>
                      )}
                    </td>
                    <td
                      style={{
                        width: "15%",
                        fontSize: 12,
                        textAlign: "center",
                      }}
                      onClick={
                        c.reminders.length
                          ? () => {
                              setShowReminders(true);
                              setSelectedContact(c);
                            }
                          : () => null
                      }
                    >
                      {hasYesterday.reduce((a, b) => a + b) ? (
                        <AlertBadge data={hasYesterday} header={"Vencidos"} />
                      ) : hasToday.reduce((a, b) => a + b) ? (
                        <AlertBadge data={hasToday} header={"Hoy"} />
                      ) : hasTomorrow.reduce((a, b) => a + b) ? (
                        <AlertBadge data={hasTomorrow} header={"Mañana"} />
                      ) : hasWeek.reduce((a, b) => a + b) ? (
                        <AlertBadge data={hasWeek} header={"Semana"} />
                      ) : hasMonth.reduce((a, b) => a + b) ? (
                        <AlertBadge data={hasMonth} header={"Semana"} />
                      ) : (
                        <p
                          style={{
                            color: "white",
                            backgroundColor: "black",
                            borderRadius: 8,
                          }}
                        >
                          Sin recordatorios
                        </p>
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: 10,
                        width: "4%",
                        textAlign: "center",
                      }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          size="sm"
                        >
                          <FcSettings />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setShowModal(true);
                              setSelectedContact(c);
                            }}
                          >
                            Agregar recordatorio
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setShowEngagement(true);
                              setSelectedContact(c);
                            }}
                          >
                            Agregar Tarea
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Alert variant="warning" style={{ marginTop: 20 }}>
            <h5>Sin Datos</h5>
          </Alert>
        )}
      </Col>
    </Row>
  );
};

export default ContactsEditorActive;
