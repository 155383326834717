import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal, Row, Col, Form, Container, Button } from "react-bootstrap";
import config from "../config/api.json";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { AuthContext } from "../store/autentication/authContext";
import { ContactsContext } from "../store/contacts/contactsContext";
import clientAxios from "../services/api";
const DialogEngagement = ({ showEngagement, setShowEngagement }) => {
  const { auth } = useContext(AuthContext);
  const { user } = auth;
  const { contacts } = useContext(ContactsContext);
  const { selectedContact } = contacts;
  const [formTask, setFormTask] = useState({
    type: "",
    detail: "",
    result: "",
    contact_id: "",
    employee_id: "",
  });
  const [isValid, setIsValid] = useState(false);
  const { type, date, detail, result } = formTask;
  useEffect(() => {
    if (
      type !== "" &&
      date !== "" &&
      detail.trim() !== "" &&
      result.trim() !== ""
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [type, detail, result]);

  const handleOnChange = (e) => {
    setFormTask({
      ...formTask,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (showEngagement) {
      setFormTask({
        ...formTask,
        contact_id: selectedContact.id,
        employee_id: user.id,
      });
    }
  }, [showEngagement, selectedContact]);

  const handleOnSubmit = () => {
    if (isValid) {
      var data = formTask;
      data["date"] = dayjs(data["date"]).format("YYYY-MM-DD");
      clientAxios.post("engagement", { ...formTask }).then(
        (r) => {
          toast.success("Guardado", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setFormTask({
            type: "",
            detail: "",
            result: "",
            contact_id: selectedContact.id,
            employee_id: user.id,
          });
        },
        (e) => {
          toast.error("No se pudo guardar la tarea intentalo más tarde", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      );
    }
  };

  const handleReset = () => {
    setShowEngagement(false);
    setFormTask({
      type: "",
      detail: "",
      result: "",
      contact_id: "",
      employee_id: "",
    });
  };
  return (
    <Modal
      show={showEngagement}
      onHide={handleReset}
      dialogClassName="modal-90w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="modalEngagement">Agregar Tarea</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={12}>
              <Form>
                <Form.Group as={Row} controlId="formTask">
                  <Form.Label column md="4">
                    Tarea
                    <span> *</span>
                  </Form.Label>
                  <Col md="8">
                    <Form.Control
                      as="select"
                      name="type"
                      onChange={handleOnChange}
                      value={type}
                    >
                      <option value="">---Selecciona---</option>
                      {"tasks" in config
                        ? Object.keys(config.tasks).map((t, i) => (
                            <option value={t} key={i}>
                              {t}
                            </option>
                          ))
                        : ""}
                    </Form.Control>
                  </Col>
                </Form.Group>
                {type !== "" ? (
                  <Form.Group as={Row} controlId="formTask">
                    <Form.Label column md="4">
                      Resultado
                      <span> *</span>
                    </Form.Label>
                    <Col md="8">
                      <Form.Control
                        as="select"
                        name="result"
                        onChange={handleOnChange}
                        value={result}
                      >
                        <option value="">---Selecciona---</option>
                        {config.tasks[type].map((t, i) => (
                          <option value={t} key={i}>
                            {t}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                ) : (
                  ""
                )}
                <Form.Group as={Row} controlId="formDetail">
                  <Form.Label column md="4">
                    Detalle
                    <span> *</span>
                  </Form.Label>
                  <Col md="8">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      autoComplete="off"
                      name="detail"
                      onChange={handleOnChange}
                      value={detail}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ justifyContent: "center" }}>
                  <Button
                    variant="success"
                    type="button"
                    onClick={handleOnSubmit}
                    disabled={!isValid}
                  >
                    Guardar
                  </Button>{" "}
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default DialogEngagement;
