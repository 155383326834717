import React, { Fragment, useContext} from "react";
import { AuthContext } from "../store/autentication/authContext";
import DashSupervisor from '../pages/dashboards/DashSupervisor';
import DashEmployee from '../pages/dashboards/DashEmployee';



const Dashboard = () => {
   const { auth } = useContext(AuthContext);
  return (
    <Fragment>
     {
       auth.user ? auth.user.role ==='employee' ? <DashEmployee/> : <DashSupervisor/>: null
     }
    </Fragment>
  )
}

export default Dashboard
