import Types from "../types";
const statsReducer = (state, action) => {
  switch (action.type) {
    case Types.getStatsContacts:
      return {
        ...state,
        statsContacts: action.payload,
      };
    case Types.getStatsEngagements:
      return {
        ...state,
        statsEngagements: action.payload,
      };
    case Types.getStatsReminders:
      return {
        ...state,
        statsReminders: action.payload,
      };
    case Types.setErrorsStats:
      return {
        ...state,
        hasErrors: [...state.hasErrors, action.payload],
      };
    
    default:
      return state;
  }
};

export default statsReducer;
