import "./login.css";
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../store/autentication/authContext";
import { Alert } from "react-bootstrap";

export default function Login(props) {

  const [form, setLogin] = useState({
    username: "",
    password: "",
  });
  const { username, password } = form;
  const [isValid, setIsValid] = useState(true);


  const { auth, login } = useContext(AuthContext);
  const { user, isServerError, isLoginError } = auth;
  const handleChangeLogin = (e) => {
    setLogin({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    if (username !== "" && password !== "") {
      login(form);
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    if (user) {
      props.history.push("/");
    }
  }, [user, props.history]);

 
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-lg-6 col-md-6 d-none d-md-block infinity-image-container"
          
        ></div>

        <div className="col-lg-6 col-md-6 infinity-form-container">
          <div className="col-lg-9 col-md-12 col-sm-9 col-xs-12 infinity-form">
            <div className="text-center mb-3 mt-5">
              <img
                src="https://image.flaticon.com/icons/png/512/4796/4796228.png"
                alt=""
                width="100px"
              />
            </div>
            <div className="text-center mb-4">
              <h4>Ingresar</h4>
            </div>
            {!isValid ? (
              <Alert variant="danger">
                Por favor ingresa tus datos para continuar
              </Alert>
            ) : (
              ""
            )}
            {isServerError ? (
              <Alert variant="danger">
                Hubo en error al conectarse con el server :'(, por favor intenta
                mas tarde
              </Alert>
            ) : null}

            {isLoginError ? (
              <Alert variant="warning">
                Los datos que ingresaste no son correctos
              </Alert>
            ) : null}
            <form className="px-3" onSubmit={handleSubmitLogin}>
              <div className="form-input">
                <span>
                  <i className="fa fa-user"></i>
                </span>
                <input
                  type="text"
                  autoComplete="username"
                  name="username"
                  placeholder="Usuario"
                  tabIndex="10"
                  onChange={handleChangeLogin}
                  value={username}
                  required
                />
              </div>
              <div className="form-input">
                <span>
                  <i className="fa fa-lock"></i>
                </span>
                <input
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  tabIndex="10"
                  value={password}
                  onChange={handleChangeLogin}
                  autoComplete="current-password"
                />
              </div>
              <div className="mb-3">
                <button className="btn btn-block" type="submit">
                  Ingresar
                </button>
              </div>
            </form>

            <div className="text-right">
              <a href="!#" className="forget-link">
                Recuperar contraseña?
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
