const excel = {
  icon: '<i class="fas fa-file-excel" style="color: green"></i>',
  index: 0,
  title: "Reporte Detallado",
  appendTo: "right",
  click: null,
};

const chartGenerator = {
  // labelSeries, dataSeries, excel function, labelAxis
  barGroupCategories: (
    labelSeries,
    dataSeries,
    categories,
    excelFun,
    labelAxis
  ) => {
    return {
      // Add data
      series: labelSeries.map((e) => ({ name: e, data: dataSeries.shift() })),
      options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
              customIcons: [
                {
                  ...excel,
                  click: excelFun,
                },
              ],
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          title: {
            text: labelAxis,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " " + labelAxis;
            },
          },
        },
      },
    };
  },

  barOneCategory: (dataSeries, categories, excelFun, title) => {
    return {
      /*series: [{
                data: dataSeries
              }],
              options: {
                chart: {
                  type: 'bar',
                  height: 350
                },
                plotOptions: {
                  bar: {
                    borderRadius: 4,
                    horizontal: true,
                  }
                },
                dataLabels: {
                  enabled: false
                },
                xaxis: {
                  categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
                    'United States', 'China', 'Germany'
                  ],
                }
              },*/
      series: [
        {
          data: dataSeries,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
              customIcons: [
                {
                  ...excel,
                  click: excelFun,
                },
              ],
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        colors: ["#00cc66", "#ff9900", "#0066ff", "#b0b0b0"],
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        title: {
          text: title,
          align: "center",
          floating: true,
        },
        tooltip: {
          theme: "dark",
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
        },
      },
    };
  },

  barStacked : (labelSeries,
    dataSeries,
    categories,
    title) => {
      return {
        series: labelSeries.map((e) => ({ name: e, data: dataSeries.shift() })),
          options: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            stroke: {
              width: 1,
              colors: ["#fff"],
            },
            title: {
              text: title,
            },
            xaxis: {
              categories: categories,
              labels: {
                formatter: function (val) {
                  return val;
                },
              },
            },
            yaxis: {
              title: {
                text: undefined,
              },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val;
                },
              },
            },
            fill: {
              opacity: 1,
            },
            legend: {
              position: "top",
              horizontalAlign: "left",
              offsetX: 40,
            },
          },
      }
  }
};

export default chartGenerator;
