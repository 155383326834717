import React, { useState } from "react";
import { useContext } from "react";
import {
  Button,
  Dropdown,
  Image,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../store/autentication/authContext";
import nophoto from "../assets/user.png";
// Session

const Header = () => {
  const { auth, logout } = useContext(AuthContext);
  const [menu, setShowMenu] = useState(false);
  // eslint-disable-next-line no-restricted-globals

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{
        backgroundColor: !auth.user ? "#18bc9c" : auth.user.profile.color,
      }}
      variant="dark"
    >
      <Navbar.Brand>Contactos CRM</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={NavLink} to="/">
            Inicio
          </Nav.Link>
          <NavDropdown title="Tareas" id="collasible-nav-dropdown">
            <NavDropdown.Item as={NavLink} to="/mis-contactos">
              Mis Contactos
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/contactos-por-archivo">
              Subir por Archivo
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav
          as={Dropdown}
          onMouseEnter={() => setShowMenu(true)}
          onMouseLeave={() => setShowMenu(false)}
        >
          <Image
            className="navbar-nav"
            src={!auth.user ? nophoto : auth.user.profile.photo}
            roundedCircle
            style={{ height: 40, width: 40, backgroundColor: "white" }}
          />
          <NavDropdown id="menu-user" title="" drop="left" show={menu}>
            <NavDropdown.Item as={NavLink} to="/mi-perfil">
              Mi Perfil
            </NavDropdown.Item>
            <Dropdown.Divider />
            <NavDropdown.Item as={Button} onClick={() => logout()}>
              Cerrar Sesión
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
