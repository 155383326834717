import React, { createContext, useReducer } from "react";
import clientAxios from "../../services/api";
import Types from "../types";
import contactsReducer from "./contactsReducer";

export const ContactsContext = createContext();

const ContactsProvider = ({ children }) => {
  const initialState = {
    list: [],
    selectedContact: null,
  };
  // Reducer
  const [contacts, dispatch] = useReducer(contactsReducer, initialState);

  const getContacts = async ({ selectedTab, user }) => {
    try {
      // clean data
      dispatch({ type: Types.getContacts, payload: [] });
      const res = await clientAxios.get(`contacts/type/${user}/${selectedTab}`);
      if (selectedTab === "actives" && res.data["data"].length) {
        var withReminders = [],
          withoutReminders = [];
        withReminders = res.data["data"].filter((c) => c.reminders.length > 0);
        withReminders = withReminders.sort((a, b) => {
          return (
            new Date(a.reminders[0].until_date) -
            new Date(b.reminders[0].until_date)
          );
        });
        withoutReminders = res.data["data"].filter(
          (c) => c.reminders.length === 0
        );
        //setContacts();
        dispatch({
          type: Types.getContacts,
          payload: [...withReminders, ...withoutReminders],
        });
      } else {
        //setContacts(data["data"]);
        dispatch({ type: Types.getContacts, payload: res.data.data });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setSelectedContact = (contact) => {
    dispatch({ type: Types.setSelectedContact, payload: contact });
  };

  const removeContact = (id) => {
    dispatch({ type: Types.removeContact, payload: id });
  };

  return (
    <ContactsContext.Provider
      value={{ contacts, getContacts, setSelectedContact, removeContact }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export default ContactsProvider;
