const Types = {
  loginUser: "login - user",
  logoutUser: "logout - user",
  setProfileColor: 'set - profile -color',
  setProfilePhoto: 'set - profile - photo',
  handleLoginError: "handle - login - error",
  handleServerError: "handle - server - error",
  getContacts: "get - contacts",
  setSelectedContact: "set - selected - contact",
  removeContact: "remove - contact",
  getSession: "set - session",
  logout: "logout",
  removeLoginErrors: "remove - login - errors",
  setActualRoute: "set - actual - route",
  getStatsContacts: "get - stats - contacts",
  getStatsEngagements: "get - stats - engagements",
  getStatsReminders: "get - stats - reminders",
  setErrorsStats: "set - errors - stats",
};

export default Types;
